@use "./variables" as *;

@mixin mobile {
    @media only screen and (max-width: $mobile-width) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}

@mixin laptop {
    @media only screen and (max-width: $laptop-width) {
        @content;
    }
}
@mixin minlaptop {
    @media only screen and (min-width: $laptop-width) {
        @content;
    }
}
@mixin hd {
    @media only screen and (max-width: $hd-width) {
        @content;
    }
}