@use "../../scss/" as *;

@mixin input() {
  padding: 20rem;
  // border: 1rem solid rgba(0, 16, 61, 0.12);
  border: 0;
  border-radius: 12rem;
  background-color: $gray-100;
  @include fnt(20, 24, $gray-900, 400);
  transition: 0.15s linear;
  outline: none;
  height: 64rem;
  &:disabled {
    color: $gray-500;
    background-color: $gray-100;
  }
}

.damageContainer {
  display: flex;
  flex-direction: column;
  gap: 12rem;
  margin-bottom: 135rem;

  .damageItem {
    background-color: $gray-100;
    border-radius: 8rem;
    padding: 8rem 12rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;

    .damageTitle {
      @include fnt(20, 24, $weight: 600, $font: $fontText, $color: $gray-900);
    }

    .containerDamageData {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        @include fnt(16, 20, $weight: 400, $font: $fontText, $color: $gray-500);
      }
    }
  }

  .deleteButton {
    @include fnt(16, 20, $weight: 400, $font: $fontText, $color: $error-500);
  }
}

.label-date-bombing {
  @include fnt(24, 30, $gray-900, 600);
}

.formInput {
  @include input();
  @include fnt(20, 24, $gray-900, 400);

  &.paddingIco {
    padding: 8rem 8rem 8rem 30rem;
  }

  &.timeDateIco {
    padding: 8rem 8rem 8rem 30rem;
  }
}

.sliderButtonAll {
  grid-column-start: 1;
  grid-column-end: 3;

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 12rem !important;
  border: 1rem solid $gray-300;
  border-radius: 24rem;
  background-color: $white;
  @include fnt(16, 24, $weight: 400, $font: $fontText, $color: $gray-900);

  object {
    margin: 0;
  }

  &:hover {
    background-color: $white;
  }
}

.containerImagePicker {
  display: grid;
  grid-template-columns: repeat(4, minmax(76rem, 1fr));
  gap: 8rem;

  .imageItem {
    width: 100%;
    object-fit: cover;
    height: 76rem;
  }

  .imageItemContainer {
    position: relative;
  }

  .removeButton {
    position: absolute;
    top: 0;
    right: 0;
    background: #2c2d2e80;
    pointer-events: all;
    padding: 4rem;
    border-radius: 8rem;
  }
}

.formTextAreaAdditionStyle {
    background-color: #FFFFFF;
}

.count-input-field {
  display: flex;
  flex-direction: column;

  .count-input-field-label {
    @include fnt(16, 24, $gray-700, 400, $font: $fontText);
    margin-left: 12rem;
    margin-bottom: 4rem;
  }

  .count-input-field-main-input {
    width: 200rem;
    padding: 20rem;
    // border: 1rem solid rgba(0, 16, 61, 0.12);
    border-radius: 12rem;
    background-color: #FFFFFF;
    @include fnt(20, 24, $gray-900, 400);
    transition: 0.15s linear;
    outline: none;
    height: 64rem;
    border: none;
    //margin:  0 0 0 12rem;

    &:disabled {
      color: $gray-500;
      background-color: $gray-100;
    }
  }
}