@use "../../scss/" as *;

.logoContainer{
    display: flex;
    gap: 12rem;
    align-items: center;

    .titlePage{
        @include fnt(32, 40, $weight:600, $color: $gray-900,$font: $fontText);
    }
}

.containerLogin{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 32rem;

    .titleText{
        @include fnt(20, 32, $weight:400, $color: $gray-900,$font: $fontText);
        max-width: 95%;
    }
}

.containerButton{
    margin-bottom: 30rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;

    .whiteButton{
        background-color: inherit;
        @include fnt(16, 24, $weight:400, $color: $gray-500,$font: $fontText);
        padding: 8rem;
    }
}

.flexCotnainer{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}