@use "../../scss/" as *;


.containerErrorPage{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12rem;

    h1{
        @include fnt(150, 136, $weight: 700, $color: $orange-dark-500);
    }
    h2{
        @include fnt(40, 56, $weight: 400, $color: $orange-dark-500);
    }

    .orangeButton{
        background-color: inherit;
        border: 1rem solid $orange-dark-500;
        @include fnt(14, 20, $weight: 400, $color: $orange-dark-500);

        &:hover{
            background-color: $orange-dark-500;
            color: $white;
        }
    }
}