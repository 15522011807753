@use "../../scss/" as *;

.mb-12{
    margin-bottom: 120rem !important;
}

.containerPageSlide{
    display: flex;
    flex-direction: column;
    gap: 32rem;
    flex-grow: 1;
    flex: 1 0 auto;

    

    .titleSlide{
        @include fnt(24, 32, $weight:600, $font: $fontText, $color: $gray-900);
    }

    .formContainer{
        display: flex;
        flex-direction: column;
        gap: 24rem;
    }

    .dateActs{
        @include fnt(20, 32, $weight:400, $font: $fontText, $color: $gray-900);
    }
}

.containerButtonSlider{
    display: grid;
    grid-template-columns: 48rem 1fr;
    gap: 8rem;
    width:100%;
    flex: 0 0 auto;

    .sliderButton{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 12rem;
        border: 1rem solid $gray-300;
        border-radius: 24rem;
        background-color: $white;
        @include fnt(16, 24, $weight:400, $font: $fontText, $color: $gray-900);

        object{
            margin: 0;
        }
    }
}

.formAddress{
    position: relative;
}

.addressContainer{
    border-radius: 8rem;
    border: 1rem $gray-200;
    background: $white;
    padding: 4rem;
    box-shadow: 0px 4rem 6rem -2rem rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    max-height: 360rem;
    position: absolute;
    z-index: 1000;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer и Edge */
    padding-bottom: 0;

    .optionsItem{
        padding: 10rem 8rem;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;


        &:hover{
            background: $gray-100;
        }
        &.focus{
            background: $gray-50;
        }

        p{
            color: $gray-900;
            font-family: $fontUI;
            font-size: 16rem;
            font-style: normal;
            font-weight: 400;
            line-height: 24rem; /* 150% */
            margin: 0;
            @include fnt(20, 24, $gray-900, 400);
        }
    }
}